import React from 'react'
import { createTheme, ThemeProvider, Box } from "@mui/material"
import Header from '../Header'
import { Container } from '@mui/system'

function Wrapper({ children }) {

    const theme = createTheme({
        palette: {
            custom_purple: {
                main: '#5955b3',
                light: '#5955b3',
                dark: '#5955b3',
                contrastText: '#fff'
            },
        }
    })

    return (
        <ThemeProvider theme={theme}>
            <Header />
            <Box sx={{
                height: { sm: `calc(100vh-69px)` },
                overflow: 'hidden',
                overflowY: 'scroll',
                padding: '30px',
                "&::-webkit-scrollbar": {
                    width: 0
                },
                "&::-webkit-scrollbar-track": {
                    backgroundColor: "#b9b7ff",
                    borderRadius: '5px'
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#5955b3",
                    borderRadius: '5px'
                },
                position: "relative"
            }}>
                <Container maxWidth="xl">
                    {children}
                </Container>
            </Box>
        </ThemeProvider>
    )
}

export default Wrapper